import ProgressBar from '../../progress-bar';

import { useGlobal } from '../../../context';

import "./nav-header.css";
import "./top-header.css";

const Header = () => {
  const { progressBarStep } = useGlobal();

  return (
    <header>
      {/** TOP HEADER */}
      <div className="style-top-hdr-0">
        <div className="style-top-hdr-1">
          <ul className="style-top-hdr-2">
            <li className="style-top-hdr-3">
              <div className="style-top-hdr-4">
                <i aria-hidden="true" className="material-icons">location_on</i>
              </div>
              <div className="style-top-hdr-6">
                <p className="style-top-hdr-7">Paris-France</p>
              </div>
            </li>
            <li className="style-top-hdr-8">
              <div className="style-top-hdr-9">
                <i aria-hidden="true" className="material-icons">email</i>
              </div>
              <div className="style-top-hdr-11">
                <p className="style-top-hdr-12">
                  <a
                    href="mailto:sales@takedown.express"
                    className="style-top-hdr-13"
                  >
                    sales@takedown.express
                  </a>
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className="style-top-hdr-14">
          <div className="style-top-hdr-15">
            <ul className="style-top-hdr-16"></ul>
            <div className="style-top-hdr-17"></div>
          </div>
        </div>
      </div>

      {/** NAVIGATION HEADER */}
      <nav className="style-nav-0">
        <div className="style-nav-1">
          <div className="style-nav-2">
            <div className="style-nav-3">
              <a href="https://takedown.express/" className="style-nav-4">
                <img
                  decoding="async"
                  loading="lazy"
                  className="style-nav-5"
                  width="180"
                  height="50"
                  src="https://takedown.express/wp-content/uploads/2023/01/cropped-R3CON3.png"
                  alt="Takedown Express"
                />{" "}
                <img
                  decoding="async"
                  loading="lazy"
                  className="style-nav-6"
                  width="180"
                  height="50"
                  src="https://takedown.express/wp-content/uploads/2023/01/cropped-R3CON3.png"
                  alt="Takedown Express"
                />{" "}
              </a>
            </div>
          </div>
        </div>
        <ProgressBar steps={3} currentStep={progressBarStep} />
      </nav>
    </header>
  );
};

export default Header;
