const ProgressBar = ({ steps, currentStep }) => {
  const renderSteps = () => {
    return Array.from({ length: steps }, (_, index) => (
      <div
        key={index}
        style={{
          width: '80px',
          height: '10px',
          backgroundColor: index + 1 <= currentStep ? (index + 1 === 1 ? '#183250' : index + 1 === 2 ? '#869fb3' : "#00edb9") : '#f7f7f9',
          border: 'solid #777181 1px' ,
          marginRight: '4px',
        }}
      ></div>
    ));
  };

  return <div style={{ display: 'flex' }}>{renderSteps()}</div>;
};

export default ProgressBar;