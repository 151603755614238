import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useGlobal } from '../../context';

import Mandate from '../../assets/mandate/takedown-mandate';
import SignPad from '../sign-pad';

import './index.css';

const SignPage = () => {
  const { signatureImage, value, setValue, setProgressBarStep } = useGlobal();
  const { company, firstname, lastname, email, product_ref } = value;
  const { state } = useLocation();
  
  if (!state) {
    window.location = window.location?.referrer || 'https://takedown.express/tarifs/';
  }

  const { token, targetDomain, legitDomain } = state;
  const [loading, setLoading] = useState(false);

  const handleConfirmRequest = async () => {
    setLoading(true);
    
    try {
      const response = await fetch(`${window.env.API_URL}/step-3`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ 
          token, firstname, lastname, email, company, legit_url: legitDomain, target_url: targetDomain, signatureImage, product_ref 
        })
      })

      if (response.ok) {
        const { url } = await response.json();
        window.location = url;
      } 

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }     
  }

  useEffect(() => { setValue((prev) => ({ ...prev, product_ref })) }, [product_ref, setValue])
  useEffect(() => { setProgressBarStep(3) }, [setProgressBarStep])

  return <div id='sign-page'>
    <Mandate data={{ company, firstname, lastname }} signatureImage={signatureImage} />
    <p>Utilisez la souris ou l'écran tactile afin d'apposer votre signature sur le mandat ci-dessus:</p>
    <SignPad />
    <p>Merci de vérifier que votre signature apparait sur le mandat avant de cliquer sur "SIGNER & PAYER".</p>
    <button className='pricing__btn thm-btn' onClick={handleConfirmRequest} disabled={loading}>SIGNER & PAYER</button>
    { loading && <p>Redirecting to payment page ...</p>}
  </div>
}

export default SignPage;
