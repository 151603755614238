import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGlobal } from '../../context';

import './index.css';

const ConfirmationForm = () => {
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const { value, setValue, setProgressBarStep } = useGlobal();
  const { firstname, lastname, company } = value;

  const [errorMessage, setErrorMessage] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValue(previous => ({ ...previous, [name]: value }));
  }

  const [isDomainConfirmed, setIsDomainConfirmed] = useState(false);
  const [missingRequiredField, setMissingRequiredField] = useState(true);
  const [localState, setLocalState] = useState({
    legitDomain: '',
    targetDomain: '',
    confirmTargetDomain: '',
  });

  const checkMissingField = useCallback((fields) => {
    const state = { ...value, ...localState };
    let missing = false;
    
    fields.forEach(field => {
      if (state[field]?.trim() === "") {
        missing = true;
      }
    });
    
    setMissingRequiredField(missing);
  }, [localState, value])

  const checkToken = useCallback(async () => {
    const token = searchparams.get("token");
    if (!token) navigate('/step-1');
    else {
      try {
        const response = await fetch(`${window.env.API_URL}/step-2`, { 
          method: 'POST', 
          mode: 'cors',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ token }) 
        })
        if (response.status !== 200) navigate('/step-1');
      } catch (error) {
        navigate('/step-1');
      }
    }
  }, [searchparams, navigate])

  useEffect(() => {
    setProgressBarStep(2);
  }, [setProgressBarStep])

  useEffect(() => {
    checkToken()
  }, [checkToken])


  useEffect(() => {
    checkMissingField([
      'legitDomain', 'targetDomain', 'confirmTargetDomain', 'firstname', 'lastname', 'company'
    ]);
  }, [checkMissingField])

  const handleLocalChange = (e) => {
    const { name, value } = e.target;

    if (['targetDomain', 'confirmTargetDomain'].includes(name)) {
      const other = ['targetDomain', 'confirmTargetDomain'].find(domain => domain !== name)
      setIsDomainConfirmed(value === localState[other]);
    }

    setLocalState(previous => ({ 
      ...previous, [name]: value 
    }));
  }

  useEffect(() => {
    if (localState.targetDomain && localState.confirmTargetDomain && localState.targetDomain !== localState.confirmTargetDomain) 
      setErrorMessage('Le domaine cible et la confirmation du domaine cible doivent être identiques.')
    else setErrorMessage();
  }, [localState])

  return <div id='confirmation-form'>
    { errorMessage && <div className='error-message'>{errorMessage}</div> }
    <form id='personal-and-company-info' onChange={handleChange}>
      <h3 className='form-label'>Vérifiez vos informations personnelles et professionnelles.</h3>
      <input type='text' placeholder='Prénom' name='firstname' defaultValue={firstname}/>
      <input type='text' placeholder='Nom de famille' name='lastname' defaultValue={lastname}/>
      <input type='text' placeholder={`Nom de l'entreprise`} name='company' defaultValue={company}/>
    </form>

    <form onChange={handleLocalChange}>
      <h3 className='form-label'>Indiquez votre domaine légitime</h3>
      <input type='url' placeholder='https://exemple.com' name='legitDomain' defaultValue={localState.legitDomain}/>
      <h3 className='form-label'>Indiquez le domaine cible à supprimer</h3>
      <input type='url' placeholder='https://exemple-cible.com' name='targetDomain' defaultValue={localState.targetDomain}/>
      <input 
        type='url' 
        placeholder='confirmez le domaine cible' 
        name='confirmTargetDomain' 
        defaultValue={localState.confirmTargetDomain}
        onPaste={(e) => e.preventDefault()}  
      />
    </form>
    <button 
      onClick={() => navigate('/step-3', { state: {
        token: searchparams.get('token'),
        ...value,
        ...localState 
      }})} 
      className='pricing__btn thm-btn'
      onMouseEnter={() => setProgressBarStep(3)} 
      onMouseLeave={() => setProgressBarStep(2)} 
      disabled={!isDomainConfirmed || missingRequiredField }>
      SUIVANT
    </button>
  </div>
}

export default ConfirmationForm
