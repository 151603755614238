import React from "react";
import ReactDOM from "react-dom";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import GlobalContextProvider from './context';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import TakedownForm from './components/takedown-form';
import ErrorPage from './components/error-page';
import ConfirmationForm from './components/confirmation-form';
import SignPage from './components/sign-page';
import Header from './components/wordpress-elements/header';

import './index.css';

const router = createBrowserRouter([
  {
    path: "/step-1",
    element: (
      <GoogleReCaptchaProvider reCaptchaKey={window.env.RECAPTCHA_KEY}>
        <TakedownForm />
      </GoogleReCaptchaProvider>
    ),
    errorElement: <ErrorPage />
  },
  {
    path: "/step-2",
    element: <ConfirmationForm />,
  },
  {
    path: "/step-3",
    element: <SignPage />,
  }
]);

ReactDOM.render(
  <GlobalContextProvider>
    <Header />
    <main>
      <RouterProvider router={router} />
    </main>
  </GlobalContextProvider>
, document.getElementById("root"));
