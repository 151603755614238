import SignatureCanvas from 'react-signature-canvas'

import './index.css';
import { useRef } from 'react';
import { useGlobal } from '../../context';

const SignPad = () => {
  const signRef = useRef();

  const { setSignatureImage } = useGlobal();

  const handleSaveSignature = () => {
    if (signRef.current) {
      setSignatureImage(signRef.current?.toDataURL());
    }
  }

  return <div id='sign-pad'>
      <SignatureCanvas    
        onEnd={handleSaveSignature}
        ref={signRef}
        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
      />
  </div>
}

export default SignPad;