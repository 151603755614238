import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useGlobal } from '../../context';

import './index.css';

const TakedownForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { value, setValue, setProgressBarStep } = useGlobal();
  const [searchparams] = useSearchParams();
  const product_ref = searchparams.get('product')
  useEffect(() => { 
    if (product_ref) { 
      setValue(previous => ({ ...previous, product_ref }));
      const savedValue = JSON.parse(localStorage.getItem('takedown-express-information'));
      localStorage.setItem('takedown-express-information', JSON.stringify({ ...savedValue, product_ref }));
    } else window.location = "https://takedown.express/tarifs/";
  }, [product_ref, setValue])

  const { firstname, lastname, company, email } = value;

  const [errorMessage, setErrorMessage] = useState();

  const [checkedTermsOfUse, setCheckedTermsOfUse] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [requestSent, setRequestSent] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleCaptchaVerification = async () => {
    try {
      const result = await executeRecaptcha("step-1");
      setRecaptchaToken(result);
    } catch (error) {
      console.error("Echec de la vérification du captcha.");
    }
  }

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === 'checkedTermsOfUse') {
      setCheckedTermsOfUse(checked);
      return;
    }

    setValue(previous => ({ ...previous, [name]: value }));
  }

  const handleRequest = async (e) => {
    e.preventDefault();

    try {
      await fetch(`${window.env.API_URL}/step-1`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ firstname, lastname, email, company, recaptchaToken, product_ref })
      })

      setCheckedTermsOfUse(false);
      setRequestSent(true);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setProgressBarStep(1);
  }, [setProgressBarStep])

  const checkBlackListed = (email) => {
    const blacklistedDomains = ['gmail', 'outlook', 'yahoo', 'protonmail', 'aol'];
    const atIndex = email.indexOf('@');
    const dotIndex = email.lastIndexOf('.');

    if (atIndex === -1 || dotIndex === -1 || dotIndex < atIndex) {
      return true; // Invalid email format
    }

    const domain = email.substring(atIndex + 1, dotIndex);

    return blacklistedDomains.includes(domain);
  }

  useEffect(() => {
    const required = ['firstname', 'lastname', 'email', 'company'],
          hasMissingField = required.some(field => !value[field]);
    const isBlackListed = checkBlackListed(value['email']); 
    
    if (value['email'] && isBlackListed) setErrorMessage('Merci d’utiliser un email professionnel.') 
    else setErrorMessage()

    setButtonDisabled(hasMissingField || !checkedTermsOfUse || isBlackListed);
  }, [value, checkedTermsOfUse])

    return (<div id='takedown-page'>
        { errorMessage && <div className='error-message'>{errorMessage}</div> }
        <h4>VERIFIEZ VOTRE IDENTITE</h4>
        <p>Vous avez choisi l'option <b>One Time Express Takedown{product_ref === "takedownplus" ? '+' : ''}</b></p>

        <form id='takedown-form' onChange={handleChange}> 
            <input type='text' placeholder='Prénom' name='firstname' defaultValue={firstname} />
            <input type='text' placeholder='Nom de famille' name='lastname' defaultValue={lastname} />
            <input type='text' placeholder={`Nom de l'entreprise`} name='company' defaultValue={company} />
            <input type='email' placeholder='Adresse email professionnelle' name='email' defaultValue={email} />

            <p>J’accepte les <a href='https://takedown.express/cgv/'>CGV</a>:</p>
            <div className="checkbox-wrapper-49">
                <div className="block">
                    <input type='checkbox' data-index="0" id="cheap-49" name='checkedTermsOfUse' defaultChecked={checkedTermsOfUse}/>
                    <label htmlFor="cheap-49"></label>
                </div>
            </div>

            <GoogleReCaptcha action="step-1" onVerify={handleCaptchaVerification} />
            { !requestSent &&
                <button 
                  onClick={handleRequest} 
                  onMouseEnter={() => setProgressBarStep(2)} 
                  onMouseLeave={() => setProgressBarStep(1)} 
                  className='pricing__btn thm-btn' disabled={buttonDisabled}>
                  Vérifier
                </button> }
        </form>

        { requestSent && <p>Un lien vous a été envoyé à l'adresse mail suivante: <b>{email}</b>, cliquez sur le lien afin d'accéder à la prochaine étape !</p> }
        <p id='captcha-branding'>
            This site is protected by reCAPTCHA and the Google&nbsp;
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </p>
    </div>);
}

export default TakedownForm;
