import { createContext, useContext, useEffect, useState } from "react";

const GlobalContext = createContext()

export const useGlobal = () => useContext(GlobalContext)

const GlobalContextProvider = ({ children }) => {
  const [value, setValue] = useState({ 
    firstname: '', 
    lastname: '', 
    email: '',
    product_ref: ''
  })
  const [signatureImage, setSignatureImage] = useState();

  useEffect(() => {
    const savedValue = JSON.parse(localStorage.getItem('takedown-express-information'));
    setValue(previous => ({ ...previous, ...savedValue }));
  }, [])

  useEffect(() => {
    console.log(value);
    localStorage.setItem('takedown-express-information', JSON.stringify(value));
  }, [value])

  const [progressBarStep, setProgressBarStep] = useState(1);

  return <GlobalContext.Provider value={{ value, setValue, signatureImage, setSignatureImage, progressBarStep, setProgressBarStep }}>
    { children }
  </GlobalContext.Provider>
}

export default GlobalContextProvider;
